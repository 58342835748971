<div class="header">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Behovsanalys, resultat</mat-card-title>
    </mat-card-header>
  </mat-card>
</div>
<div class="card-spacer"></div>
<div class="areas">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Uppfyllelse av behov</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="area" *ngFor="let area of survey.areas; first as isFirst">
        <hr *ngIf="!isFirst" />
        <table>
          <tr class="area">
            <td class="title">{{ area.title }}</td>
            <td class="score" [style.color]="area.color">
              <div class="progress-bar-outline">
                <div
                  class="progress-bar-fill"
                  [style.backgroundColor]="area.color"
                  [style.width]="percentage(area.score) + '%'"
                >
                  &nbsp;
                </div>
              </div>
            </td>
            <td class="score" [style.color]="area.color">
              {{ percentage(area.score) }}&nbsp;%
            </td>
          </tr>
          <tr>
            <td colspan="3">{{ area.description }}</td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="questions">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Påståenden och svar i detalj</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <table>
        <ng-container *ngFor="let a of survey.areas">
          <tr>
            <td colspan="3" class="area-title">{{ a.title }}</td>
          </tr>
          <ng-container *ngFor="let g of a.groups">
            <tr *ngFor="let q of g.questions">
              <td>{{ q.title }}</td>
              <td class="score" [style.color]="color(q.score)">
                <div class="progress-bar-outline">
                  <div
                    class="progress-bar-fill"
                    [style.backgroundColor]="a.color"
                    [style.width]="percentage(q.score) + '%'"
                  >
                    &nbsp;
                  </div>
                </div>
              </td>
              <td class="answer">
                <div class="answer" [style.backgroundColor]="a.color">
                  {{ q.answer }}
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </mat-card-content>
  </mat-card>
</div>
