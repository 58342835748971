import { Component, Input } from "@angular/core";

@Component({
  selector: "loading-indicator",
  templateUrl: "./loading-indicator.component.html",
  styleUrls: ["./loading-indicator.component.scss"],
})
export class LoadingIndicatorComponent {
  @Input() state: LoadingState;

  constructor() {}
}

export class LoadingState {
  loading: string = null;
  errors: string[] = [];

  setLoading(message: string = "Laddar") {
    this.loading = message;
    this.errors = [];
  }

  addError(message: string) {
    this.loading = null;
    this.errors.push(message);
  }

  clear() {
    this.loading = null;
    this.errors = [];
  }
}
