<div class="header">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Behovsanalys, frågeformulär</mat-card-title>
    </mat-card-header>
  </mat-card>
</div>
<div class="card-spacer"></div>

<loading-indicator [state]="state"></loading-indicator>

<div *ngIf="survey && survey.state == 'open'">
  <div *ngFor="let question of survey.questions">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ question.title }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="option-list">
          <div *ngFor="let option of question.options" class="option">
            <button
              *ngIf="option.answer != question.answer"
              (click)="selectAnswer(question, option)"
              mat-stroked-button
              color="primary"
            >
              {{ option.answer }}
            </button>
            <button
              *ngIf="option.answer == question.answer"
              (click)="clearAnswer(question)"
              mat-flat-button
              color="primary"
            >
              {{ option.answer }}
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="submit-button">
    <p>
      <loading-indicator [state]="submitState"></loading-indicator>
    </p>
    <button
      mat-flat-button
      color="primary"
      (click)="submitSurvey()"
      *ngIf="!submitState.loading"
    >
      Skicka in
    </button>
  </div>
</div>

<div *ngIf="survey && survey.state == 'submitted'">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Denna enkät har skickats in.</mat-card-title>
    </mat-card-header>
  </mat-card>
</div>
