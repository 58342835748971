import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { LoadingState } from "src/app/blocks/loading-indicator/loading-indicator.component";
import { SurveyService } from "src/app/service/survey.service";

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  state = new LoadingState();
  survey = null;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService
  ) {}

  ngOnInit() {
    this.state.setLoading();
    this.route.queryParams.subscribe((query) => this.navigate(query));
  }

  navigate(query: Params) {
    if (!query.k) {
      this.state.addError("Enkät-ID saknas");
    } else {
      this.surveyService
        .getSurveyByKey(query.k)
        .then((s: any) => {
          s.questions = [];
          for (let a of s.areas) {
            for (let g of a.groups) {
              for (let q of g.questions) {
                s.questions.push(q);
              }
            }
          }
          s.questions.sort((a, b) => a.sort - b.sort);
          this.survey = s;
          this.state.clear();
        })
        .catch((e) => this.state.addError(e.error));
    }
  }
}
