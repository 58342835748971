import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SurveyService {
  constructor(private http: HttpClient) {}

  getSurveyByKey(key: string): Promise<any[]> {
    let url = `/api/survey/${key}`;
    return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe(
        (survey: any) => {
          resolve(survey);
        },
        (error) => reject(error)
      );
    });
  }

  setAnswer(
    surveyKey: string,
    questionId: number,
    answer: string,
    value: number
  ) {
    let url = `/api/survey/${surveyKey}/questions/${questionId}/answer`;
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, { answer: answer, value: value }).subscribe(
        (question: any) => {
          resolve(question);
        },
        (error) => reject(error)
      );
    });
  }

  clearAnswer(surveyKey: string, questionId: number) {
    let url = `/api/survey/${surveyKey}/questions/${questionId}/answer`;
    return new Promise<any>((resolve, reject) => {
      this.http.delete(url).subscribe(
        (question: any) => {
          resolve(question);
        },
        (error) => reject(error)
      );
    });
  }

  submitSurvey(surveyKey: string) {
    let url = `/api/survey/${surveyKey}/submit`;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, null).subscribe(
        (survey: any) => {
          resolve(survey);
        },
        (error) => reject(error)
      );
    });
  }
}
