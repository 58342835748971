import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SurveyComponent } from "./pages/survey/survey.component";

const routes: Routes = [{ path: "survey", component: SurveyComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
