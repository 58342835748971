import { Component, OnInit, Input } from "@angular/core";
import { LoadingState } from "src/app/blocks/loading-indicator/loading-indicator.component";
import { SurveyService } from "src/app/service/survey.service";

@Component({
  selector: "app-survey-submit",
  templateUrl: "./survey-submit.component.html",
  styleUrls: ["./survey-submit.component.scss"],
})
export class SurveySubmitComponent implements OnInit {
  state = new LoadingState();
  submitState = new LoadingState();
  @Input()
  survey: any;

  constructor(private surveyService: SurveyService) {}

  ngOnInit() {}

  selectAnswer(question: any, option: any) {
    this.surveyService
      .setAnswer(this.survey.key, question.id, option.answer, option.value)
      .then((q) => {
        question.answer = option.answer;
        question.value = option.value;
        this.state.clear();
      })
      .catch((e) => this.state.addError(e.error));
  }

  clearAnswer(question: any) {
    this.surveyService
      .clearAnswer(this.survey.key, question.id)
      .then((q) => {
        question.answer = null;
        question.value = null;
        this.state.clear();
      })
      .catch((e) => this.state.addError(e.error));
  }

  submitSurvey() {
    this.submitState.setLoading("Skickar in");
    this.surveyService
      .submitSurvey(this.survey.key)
      .then((s) => {
        this.survey = s;
        this.submitState.clear();
      })
      .catch((e) => this.submitState.addError(e.error));
  }
}
