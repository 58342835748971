import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-survey-result",
  templateUrl: "./survey-result.component.html",
  styleUrls: ["./survey-result.component.scss"],
})
export class SurveyResultComponent implements OnInit {
  @Input()
  survey: any;

  constructor() {}

  ngOnInit() {}

  percentage(score: any) {
    let percentage = (score.total / score.max) * 100;
    return Math.round(percentage);
  }

  color(score: any) {
    let s = score.total / score.max;
    let red = Math.max(0, Math.round(255 * (1 - 1.8 * s)));
    let green = Math.max(0, Math.round(200 * s));
    let blue = Math.max(0, Math.round(55 * (1 - s)));
    return `rgb(${red},${green},${blue})`;
  }
}
